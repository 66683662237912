<script setup>

import { computed, defineEmits, reactive, ref } from 'vue';
import { i18n } from '@/Ship';
import { useOfferActions } from '@offer';
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const emit = defineEmits(['performed', 'close']);

const getOfferResource = useOfferActions('getOfferResource');
const offers = getOfferResource();
const errorText = ref('');

const languages = ref(['DE', 'EN', 'ES', 'FR', 'IT', 'PL', 'RO', 'RU']);

const payload = reactive({
	name: '',
	description: '',
});

const isPayloadValid = computed(() => {
    return payload.name
});

function makeOffer() {
    const result = offers.state.find(item => item.name === payload.name);

    payload['brand_id'] = profile.state.env.brand;

    if (!result) {
        offers.create(payload).then(() => {
            emit('close');
        }).catch(error => {
            errorText.value = error.response.data.description.name[0];
        });
    } else {
        errorText.value = i18n.global.t('campaign.offer_already_exist');
    }

}

</script>

<template>
    <form class="make-offer" v-on:submit.prevent="makeOffer">
        <g-field v-model="payload.name"
                v-on:update:modelValue="errorText = ''"
                v-bind:label="$t('campaign.offer_name', 'Offer Name')"
                v-bind:error="errorText"/>

        <g-combobox v-model="payload.language"
                    v-bind:label="$t('base.language', 'Language')"
                    v-bind:options="languages"/>

        <fieldset>
            <legend>{{ $t('base.description', 'Description') }}</legend>
            <g-textarea v-model="payload.description" label="..." rows="5"/>
        </fieldset>

        <g-flex gap="5" justify="end">
            <g-button class="cancel-btn"
                      type="button"
                      v-on:click="$emit('close')">
                {{ $t('base.cancel', 'Cancel') }}
            </g-button>
            <g-button class="save-btn"
                      type="submit"
                      v-bind:disabled="!!errorText || !isPayloadValid">
                {{ $t('base.make', 'Make') }}
            </g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.make-offer {
	width: 300px;
}

.g-combobox {
    padding-top: 8px;
}

fieldset {
	margin: 12px 0;
	text-align: left;
}

.g-button {
	width: 100px;
}
</style>